<template>


    <div class="col-sm-4">
        <div class="card" style="max-height: calc(100vh - 110px)">
            <div class="card-header">
                <div class="d-flex justify-content-start">
                    <div class="card-title mb-3 pointer" :class="{'active': type === 'chats'}" @click="addChat=false; type='chats'">
                        Histórico
                    </div>
                    <div class="card-title mb-3 pointer" :class="{'active': type === 'contacts'}" style="margin-left: 15px"
                         @click="newChat">
                        Contatos
                    </div>

                </div>
                <div class="mb-2">
                    <div class="bv-no-focus-ring my-auto w-100">
                        <div role="group"
                             class="input-group input-group-merge form-control-prepended form-control-appended">
                            <input placeholder="Buscar conversa..." v-model="search" @keyup="searchChatData"
                                   class="form-control form-control-prepended form-control-appended">
                            <div class="input-group-prepend">
                                <div class="input-group-text">
                                    <i class="fa fa-search text-muted"></i>
                                </div>
                            </div>
                            <div class="input-group-append">
                                <div class="input-group-text">
                                            <span class="fa fa-times-circle pointer text-muted"
                                                  :class="{ 'op-0 hidden': search.length === 0 }" @click="resetSearch">
                                            </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-body custom-scroll" id="list-contacts" style="overflow-y: auto">

                <div v-if="search.length === 0 && !addChat">
                    <div class="row" v-if="chatData.length > 0">
                        <div class="col-12 d-flex justify-content-between  py-2 pointer item-chat"
                             v-for="(data, index) in chatData"
                             :key="'list-messages-' + data.id"
                             @click="resetData(data.mentee)"
                             :class="{ 'active': mentee && mentee.id === data.mentee_id, 'border-top': index > 0  }">
                            <div class="d-flex w-100">
                                <img height="40" :src="`${env.url}mentees/image/${data.mentee.id}`"
                                     class="rounded-circle mr-2 my-auto"
                                     alt/>

                                <div class="ms-2 w-100" style="display: grid">
                                    <strong class="mr-auto">{{ data.mentee.name }}</strong>
                                    <small class="text-muted">{{ data.message }}</small>
                                    <small class="text-muted">{{ formatHour(data.created_at) }}</small>
                                </div>
                            </div>
                            <!--                            // Não lidas-->
                            <!--                            <div class="flex-shrink-0" v-if="data.unread_count && data.target === 'mentor'">-->
                            <!--                                <b-badge variant="primary" pill class="my-auto">-->
                            <!--                                    {{ data.unread_count }}-->
                            <!--                                </b-badge>-->
                            <!--                            </div>-->

                        </div>
                    </div>
                    <div class="row" v-else>
                        <div class="col-12 text-center  py-2 item-chat">
                            Nenhuma conversa iniciada
                        </div>
                    </div>
                </div>

                <div v-else-if="search.length === 0 && addChat">
                    <div class="row" v-if="listTotalMentees.length > 0">
                        <div class="col-12 d-flex justify-content-between  py-2 pointer item-chat"
                             v-for="data in listTotalMentees"
                             :key="'list-messages-' + data.id"
                             @click="resetData(data)">
                            <div class="d-flex w-100">
                                <img height="40" :src="`${env.url}mentees/image/${data.id}`"
                                     class="rounded-circle mr-2 my-auto"
                                     alt/>

                                <div class="ms-2 w-100 my-auto" style="display: grid">
                                    <strong class="mr-auto">{{ data.name }}</strong>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row" v-else>
                        <div class="col-12 text-center  py-2 item-chat">
                            Você ainda não possui mentorandos
                        </div>
                    </div>
                </div>

                <div v-else-if="search.length > 0 && addChat">
                    <div class="row" v-if="searchTotalMentees.length > 0">
                        <div class="col-12 d-flex justify-content-between  py-2 pointer item-chat"
                             v-for="data in searchTotalMentees"
                             :key="'list-messages-' + data.id"
                             @click="resetData(data.mentee)">
                            <div class="d-flex w-100">
                                <img height="40" :src="`${env.url}mentees/image/${data.id}`"
                                     class="rounded-circle mr-2 my-auto"
                                     alt/>

                                <div class="ms-2 w-100 my-auto" style="display: grid">
                                    <strong class="mr-auto">{{ data.name }}</strong>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row" v-else>
                        <div class="col-12 text-center  py-2 item-chat">
                            Nenhuma contanto encontrado
                        </div>
                    </div>
                </div>

                <div v-else>
                    <div class="row" v-if="listFiltered.length > 0">
                        <div class="col-12 d-flex justify-content-between  py-2 pointer item-chat"
                             v-for="(data, index) in listFiltered"
                             :key="'list-messages-' + data.id"
                             @click="resetData(data.mentee)"
                             :class="{ 'active': mentee && mentee.id === data.mentee_id, 'border-top': index > 0  }">
                            <div class="d-flex w-100">
                                <img height="40" :src="`${env.url}mentees/image/${mentee.id}`"
                                     class="rounded-circle mr-2 my-auto"
                                     alt/>

                                <div class="ms-2 w-100" style="display: grid">
                                    <strong class="mr-auto">{{ data.mentee.name }}</strong>
                                    <small class="text-muted">{{ data.message }}</small>
                                    <small class="text-muted">{{ formatHour(data.created_at) }}</small>
                                </div>
                            </div>
                            <!--                            não lidas-->
                            <!--                            <div class="flex-shrink-0" v-if="data.unread_count">-->
                            <!--                                <b-badge variant="primary" pill class="my-auto">-->
                            <!--                                    {{ data.unread_count }}-->
                            <!--                                </b-badge>-->
                            <!--                            </div>-->

                        </div>
                    </div>
                    <div class="row" v-else>
                        <div class="col-12 text-center  py-2 item-chat">
                            Nenhuma contanto encontrado
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


</template>

<script>

import http from '@/http'
import {formatHour, getDate} from "@/components/composables/masks";
import env from '@/env'

export default {

    emits: ['reset-page'],

    data() {
        return {
            messages: {
                data: []
            },
            load: true,
            addChat: false,
            message: "",
            username: "Steven Franklin",
            profile: require("@/assets/images/people/110/guy-1.jpg"),
            mentee: null,
            type: 'chats',
            page: 1,
            search: '',
            listFiltered: [],
            listTotalMentees: [],
            searchTotalMentees: [],
            env
        };
    },


    methods: {
        formatHour,
        getDate,

        normalizeText(text) {
            return text.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
        },

        searchChatData() {
            if (this.addChat) {
                this.searchTotalMentees = this.listTotalMentees.filter(chat => this.normalizeText(chat.name).includes(this.normalizeText(this.search)))
            } else {
                this.listFiltered = this.chatData.filter(chat => this.normalizeText(chat.mentee.name).includes(this.normalizeText(this.search)))
            }
        },

        newChat() {
            this.addChat = true;
            this.search = '';
            this.type = 'contacts';
        },

        resetSearch() {
            this.search = '';
            this.listFiltered = [];
            this.searchTotalMentees = [];
        },

        resetData(newMentee) {
            this.mentee = newMentee;
            this.page = 1;
            this.resetSearch();
            this.type = 'chats';
            this.addChat = false;
            this.$emit('reset-page', newMentee.id)
        },

        getListTotalMentess() {
            http.get('mentors/list-total-mentees')
                .then(response => {
                    this.listTotalMentees = response.data;
                })
                .catch(e => {
                    console.error('Get List Total Mentees: ', e);
                })
        },
    },
    mounted() {
        this.getListTotalMentess();
    },
    computed: {
        currentUser() {
            return this.$store.state.auth.user;
        },

        chatData() {
            return this.$store.state.api.listMessages;
        }
    },
};
</script>

<style scoped>
.item-chat:hover {
    background: #F5F7FA !important;
}

.page-section .form-control:focus {
    box-shadow: none !important;
    border-bottom: solid 1px #DBDBE0 !important;
    border-radius: 0 !important;
}

.card-title.active {
    border-bottom: solid #570C6A;
}

.op-0 {
    opacity: 0;
}

.hidden {
    visibility: hidden;
}

</style>
